import * as React from 'react'
import jwtDecode from 'jwt-decode'
import isEmpty from 'lodash/fp/isEmpty'

import { LocalStorageEnum } from '@/constants/localstorage'
import { getLocalStorage, setLocalStorage } from '@/utils/browser/localstorage'

/**
 * @deprecated use setAuthToken instead
 */
export const saveAuthToken = ({ authToken }) => {
  setLocalStorage({
    key: LocalStorageEnum.AuthToken,
    value: authToken,
  })
}

/**
 * @deprecated use authTokenStore instead
 */
export const getAuthToken = () => {
  if (typeof global.localStorage !== 'undefined') {
    const authToken = getLocalStorage({ key: LocalStorageEnum.AuthToken })
    return authToken || ''
  }
  return ''
}

/**
 * @deprecated use getJwtPayload instead
 */
export const getAuthTokenPayload = (token) => {
  try {
    const authToken = token || getAuthToken()
    if (!authToken) return {}

    return jwtDecode(authToken)
  } catch (error) {
    saveAuthToken({ authToken: '' })
    return {}
  }
}

/**
 * @deprecated
 */
export const isAuthTokenValid = () => {
  const { exp } = getAuthTokenPayload()
  if (!exp) return false
  return exp > new Date().getTime() / 1000
}

/**
 * @deprecated
 */
export const getAuthTokenNickname = () => {
  const payload = getAuthTokenPayload()
  return isEmpty(payload.data) ? null : payload.data.nickname
}

/**
 * @deprecated
 */
export const getAuthTokenUserId = () => {
  const payload = getAuthTokenPayload()
  const userId = isEmpty(payload.data) ? null : payload.data.user_id
  return userId ? String(userId) : null
}

/**
 * @deprecated
 */
export const removeAuthToken = () => {
  setLocalStorage({ key: LocalStorageEnum.AuthToken, value: '' })
}

const authTokenInitState = () => ({ token: getAuthToken(), userId: getAuthTokenUserId() || '' })

/**
 * @deprecated 대신에 useAuthTokenStore 사용
 */
export const useAuthToken = () => {
  const [{ token, userId }, setToken] = React.useState(authTokenInitState)
  React.useEffect(() => {
    if (token !== getAuthToken() || userId !== getAuthTokenUserId()) {
      setToken({ token: getAuthToken(), userId: getAuthTokenUserId() })
    }
  }, [token, userId])
  return { token, userId }
}
