import jwtDecode, { JwtPayload } from 'jwt-decode'

export default function isExpired(token: string, { correction }: { correction?: number } = {}) {
  try {
    const { exp } = jwtDecode(token) as JwtPayload
    return !exp || exp * 1000 - (correction ?? 0) < new Date().getTime()
  } catch (error) {
    return true
  }
}
