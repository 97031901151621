import {ApolloClient, NormalizedCacheObject} from '@apollo/client'
import * as React from 'react'

import isomorphicApolloClient, {CreateApolloClientProps} from './isomorphic-apollo-client'
import apiServerApolloClientCache from './api-server-apollo-client-cache'
import apiServerLink from './api-server-link'
import {useContext} from "react";
import {ActionCableContext} from "@/apollo-client/action-cable-provider";
import {Consumer} from "@rails/actioncable";

function createApiServerApolloClient({
                                         isServer,
                                         cable
                                     }: CreateApolloClientProps): ApolloClient<NormalizedCacheObject> {
    console.log('useContext', { cable })
    console.log('createApiServerApolloClient', { isServer, cable })
    const client = new ApolloClient({
        ssrMode: isServer,
        link: apiServerLink({cable}),
        cache: apiServerApolloClientCache(),
    })

    return client
}

const apiServerApolloClient = isomorphicApolloClient(createApiServerApolloClient)

export function useApiServerApolloClient(
    initialState: NormalizedCacheObject | null = {},
): ApolloClient<NormalizedCacheObject> {

    const cable = useContext(ActionCableContext);
    const apolloClient = React.useMemo(() => apiServerApolloClient(initialState, cable), [initialState, cable])

    return apolloClient
}

export default apiServerApolloClient
