import type { ApolloLink, ServerError } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import Router from 'next/router'

import { getAuthToken } from '@/service/auth'
import { setAuthToken } from '@/service/auth/store'
import { errorToast } from '@/utils/toast'
// 인증관련 에러 상태 코드
const forbiddenError = 403
const authCodes = [401, forbiddenError]

// 에러 메시지 토스트 팝업은 여기서 핸들링
export default function getErrorHandlerLink(): ApolloLink {
  return onError(({ graphQLErrors, networkError }) => {
    const responseStatusCode = (networkError as ServerError)?.statusCode
    const isNetworkError = typeof responseStatusCode === 'number' && responseStatusCode !== 200
    const graphQLStatusCode = graphQLErrors?.[0].extensions?.code as number
    const isAuthError =
      authCodes.includes(graphQLStatusCode) || authCodes.includes(responseStatusCode)

    // 인증 에러는 네트워크와 graphQL 응답코드 둘다 체크
    if (isAuthError) {
      const message = graphQLErrors?.[0].message
      errorToast(graphQLErrors?.[0].message, {
        toastId: message, // 같은 메시지가 연속적으로 나오지 않도록 메시지를 id로 할당
      })
      // 403 일때만 토큰제거 후 로그인 페이지로
      const savedToken = getAuthToken()
      if (
        savedToken &&
        (forbiddenError === graphQLStatusCode || forbiddenError === responseStatusCode)
      ) {
        setAuthToken('')
        Router.push('/sign-in')
      }
    } else if (updatePortfolioFromPlaidErrorHandler(graphQLErrors)) {
      errorToast(graphQLErrors?.[0].message)
      return // 컴포넌트 내부에서 자체적으로 핸들링
    } else if (isNetworkError) {
      errorToast((networkError as ServerError).message)
    } else {
      errorToast(graphQLErrors?.[0].message)
    }
  })
}

function updatePortfolioFromPlaidErrorHandler(graphQLErrors: ErrorResponse['graphQLErrors']) {
  return (
    (!!graphQLErrors &&
      graphQLErrors?.find((error) =>
        error.path?.find((path) => path === 'updatePortfolioFromPlaid'),
      )) ||
    graphQLErrors?.[0]?.extensions?.code === 400
  )
}
