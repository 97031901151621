export enum LocalStorageEnum {
  AuthToken = 'AUTH_TOKEN',
  RecentStocks = 'THERICH_RECENT_STOCKS_2',
  Locale = 'THERICH_LOCALE',
  LoginPlatform = 'THERICH_LOGIN_PLATFORM',
  CommunityRecentSearches = 'THERICH_COMMUNITY_RECENT_SEARCHES',
  CommunityTimelineInfluencer = 'THERICH_COMMUNITY_TIMELINE_INFLUENCER',
  metaPortfolioLock = 'THERICH_META_PORTFOLIO_LOCK',
  NewsCountry = 'THERICH_NEWS_COUNTRY',
  OnBoardingIntro = 'ON_BOARDING_INTRO',
  InvisibleAds = 'THERICH_INVISIBLE_ADS',
  NewsRecentSearches = 'THERICH_NEWS_RECENT_SEARCHES',
  FavoriteAssetsReorder = 'THERICH_FAVORITE_ASSETS_REORDER',
  LoanInterestCalculatorValues = 'LOAN_INTEREST_CALCULATOR_VALUES',
  ProfessionalInvestorApplicationOptionValues = 'PROFESSIONAL_INVESTOR_APPLICATION_OPTION_VALUES',
}

export const getChattingNoticeKey = ({ chatId }: { chatId: string }) => {
  return `THERICH_CHAT_NOTICE_${chatId}`
}
