import { IS_PRODUCTION } from '@/constants/env'
import { appsignal } from '@/components/third-party/appsignal'

export default function sendErrorLog(error: Error) {
  if (IS_PRODUCTION) {
    appsignal.sendError(error)
  } else {
    console.error(error)
  }
}
