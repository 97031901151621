import * as React from 'react'

const autoLoadDuration = 5 //In Seconds
const eventList = ['keydown', 'mousemove', 'wheel', 'touchmove', 'touchstart', 'touchend']

// 써드파티 스크립트 지연 로드
// delay 속성이 있는 스크립트 태그를 지연로드 시킴
export default function useScriptsDelayLoad(): void {
  React.useEffect(() => {
    const autoLoadTimeout = setTimeout(runScripts, autoLoadDuration * 1000)

    eventList.forEach(function (event) {
      window.addEventListener(event, triggerScripts, { passive: true })
    })

    function triggerScripts() {
      runScripts()
      clearTimeout(autoLoadTimeout)
      eventList.forEach(function (event) {
        window.removeEventListener(event, triggerScripts)
      })
    }

    function runScripts() {
      document.querySelectorAll('script[delay]').forEach(function (scriptTag) {
        const src = scriptTag.getAttribute('delay')
        if (src) {
          scriptTag.setAttribute('src', src)
        }
      })
    }
  }, [])
}
