import jwtDecode from 'jwt-decode'
import { makeVar, useReactiveVar } from '@apollo/client'
import * as React from 'react'

import isExpired from '@/utils/jwt/is-expired'
import { getLocalStorage, setLocalStorage } from '@/utils/browser/localstorage'
import { LocalStorageEnum } from '@/constants/localstorage'

export const authTokenStore = makeVar({ token: '', userId: '', nickname: '' })

export const getJwtPayload = (token: string) =>
  token && !isExpired(token) ? jwtDecode<AuthTokenPayload>(token) : null

export const getUserIdByToken = (token: string) => {
  const jwtPayload = getJwtPayload(token)
  return jwtPayload && jwtPayload.data?.user_id ? `${jwtPayload.data.user_id}` : ''
}

export const getNickNameByToken = (token: string) => {
  const jwtPayload = getJwtPayload(token)
  return jwtPayload && jwtPayload.data?.nickname ? `${jwtPayload.data.nickname}` : ''
}

const setAuthTokenStore = (token: string) => {
  authTokenStore({ token, userId: getUserIdByToken(token), nickname: getNickNameByToken(token) })
}

type AuthTokenPayload = {
  data: {
    nickname: string
    user_id: number
  }
  exp: number
  iat: number
  iss: string
}

export const getAuthToken = () => {
  return getLocalStorage<string>({ key: LocalStorageEnum.AuthToken })
}

export const useAuthTokenStore = () => {
  const { token, userId, nickname } = useReactiveVar(authTokenStore)

  React.useEffect(() => {
    if (!token) {
      setAuthTokenStore(getAuthToken())
    }
  }, [token])

  return { token, userId, nickname }
}

export const setAuthToken = (newToken = '') => {
  setLocalStorage<string>({ key: LocalStorageEnum.AuthToken, value: newToken })
  setAuthTokenStore(newToken ?? '')
}
