// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// 아래 코드는 아래 링크를 참고해서 fetch body도 기록하도록 만듬
//  https://github.com/appsignal/appsignal-javascript/blob/develop/packages/plugin-breadcrumbs-network/src/index.ts
import type { JSClient } from '@appsignal/types'

/**
 * Automatically adds a breadcrumb on every network request.
 */
function networkBreadcrumbsPlugin() {
  // feature detect browser features if they are enabled
  return function (this: JSClient): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const appsignal = this

    const fetchPatch = () => {
      const originalFetch = window.fetch

      function fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
        const url = typeof input === 'string' ? input : input.url

        // Assume a GET request if we can't infer the method
        const method = (typeof input !== 'string' && input.method) || (init && init.method) || 'GET'

        const metadata = {
          method,
          url,
          body: typeof init?.body === 'string' ? init.body : '',
        }

        return (
          originalFetch
            // eslint-disable-next-line prefer-rest-params
            .apply(window, arguments)
            .then((response: Response) => {
              // re-assign to fix some browsers not liking when you
              // access `response.status` directly
              const { status: statusCode } = response

              appsignal.addBreadcrumb({
                action: `Received a response with code ${statusCode}`,
                category: 'Fetch',
                metadata,
              })

              return response
            })
            .catch((error: Error) => {
              appsignal.addBreadcrumb({
                action: 'Request failed',
                category: 'Fetch',
                metadata,
              })

              throw error
            })
        )
      }

      window.fetch = fetch
    }

    fetchPatch()
  }
}

export const plugin = networkBreadcrumbsPlugin
