type Props = {
  existing: unknown[]
  incoming: unknown[]
  page: number
  limit: number
}

export default function mergeForPagination({ existing, incoming, page, limit }: Props) {
  const merged = existing
    ? incoming.length < limit // 네트워크 통해서 가져온 데이터가 이전과 달라진 경우 대응(ex: My뉴스에서 포폴을 모두 제거하거나 스위치 오프한 경우 다시 반영)
      ? existing.slice(0, page * limit + incoming.length)
      : existing.slice(0)
    : []
  for (let i = 0; i < incoming.length; ++i) {
    merged[page * limit + i] = incoming[i]
  }
  return merged
}
