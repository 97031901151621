import { createContext, useEffect, useState } from "react";
import getConfig from 'next/config'
import {Consumer} from "@rails/actioncable";

const ActionCableContext = createContext(null);

interface ICable {
  cable: Consumer
}
const { publicRuntimeConfig } = getConfig()
// actionCable Url

// @ts-ignore
const ActionCableProvider = ({ children }) => {
  const [CableApp, setCableApp] = useState({} as ICable);

  const loadConsumer = async () => {
    const { createConsumer } = await import("@rails/actioncable");
    return createConsumer;
  };

  useEffect(() => {
    if (typeof window !== "undefined" && CableApp.cable === undefined) {
      loadConsumer().then((createConsumer) => {
        const actionCableUrl = publicRuntimeConfig.subscriptionUrl;
        const cable = createConsumer(actionCableUrl)
        console.log('useEffect', cable)
        setCableApp({
          cable: cable,
        });
      });
    }
  }, []);

  // @ts-ignore
  return <ActionCableContext.Provider value={CableApp.cable}>{children}</ActionCableContext.Provider>;
};

export { ActionCableContext, ActionCableProvider };
