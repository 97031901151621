import { toast, ToastOptions, ToastContent } from 'react-toastify'

import { isMobileSize } from '../browser/resize-hook'

const commonOptions = (text: ToastContent, options?: ToastOptions): ToastOptions => {
  return {
    autoClose: 2500,
    closeButton: false,
    hideProgressBar: true,
    position: isMobileSize() ? 'bottom-center' : 'top-right',
    ...options,
  }
}

export const successToast = (text: ToastContent, options?: ToastOptions) =>
  toast.success(text, commonOptions(text, options))

export const defaultToast = (text: ToastContent, options?: ToastOptions) =>
  toast(text, commonOptions(text, options))

export const infoToast = (text: ToastContent, options?: ToastOptions) =>
  toast.info(text, commonOptions(text, options))

export const errorToast = (text: ToastContent, options?: ToastOptions) =>
  toast.error(text, commonOptions(text, options))

export const warnToast = (text: ToastContent, options?: ToastOptions) =>
  toast.warn(text, commonOptions(text, options))
