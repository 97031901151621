import * as React from 'react'
import Router from 'next/router'

/**
 * 브라우저 history 뒤로 가기시 스크롤 원복할 수 있도록 고안한 장치
 * 참고: https://github.com/vercel/next.js/issues/26031
 *
 * history 앞으로 갈 때는 기존 스크롤 높이를 회복하지 않음에 유의
 */
export default function useRestoreScrollHeight(): void {
  const isNeedRestore = React.useRef(false)
  const cachedPageHeightRef = React.useRef<number[]>([])

  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      if (!isNeedRestore.current) {
        cachedPageHeightRef.current.push(window.pageYOffset)
      }
    })

    Router.events.on('routeChangeComplete', () => {
      if (isNeedRestore.current) {
        setTimeout(() => {
          window.scrollTo(0, cachedPageHeightRef.current.pop() ?? 0)
        }, 100)
        isNeedRestore.current = false
      }
    })

    Router.beforePopState(() => {
      isNeedRestore.current = true
      return true
    })
  }, [])
}
