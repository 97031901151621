import * as React from 'react'

export const isMobileSize = () => typeof global.window === 'undefined' || window.innerWidth < 768

const useResize = ({ onResize }: { onResize: (e: Event) => void }): void => {
  // width와 height 모두 변경됨에 유의
  React.useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])
}

// 렌더 후 모바일로 판정됬을 때 true
export const useMobileSize = (init: boolean | null = false) => {
  const [isMobile, setIsMobile] = React.useState(init)
  const handleResize = React.useCallback(() => {
    setIsMobile(isMobileSize())
  }, [])
  useResize({ onResize: handleResize })

  React.useEffect(() => {
    handleResize()
  }, [handleResize])

  return { isMobile }
}

// 렌더 후 데스크탑으로 판정됬을 때 true
export const useDesktopSize = (init: boolean | null = false) => {
  const [isDesktop, setIsDesktop] = React.useState(init)
  const handleResize = React.useCallback(() => {
    setIsDesktop(!isMobileSize())
  }, [])
  useResize({ onResize: handleResize })

  React.useEffect(() => {
    handleResize()
  }, [handleResize])

  return { isDesktop }
}

export default useResize
