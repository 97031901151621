import type { CurrencyExchangeFieldsFragment } from '@/gql/generated-api1'

export type IndexingByAllExchanges = {
  fiat: {
    [key in string]: CurrencyExchangeFieldsFragment
  }
  crypto: {
    [key in string]: CurrencyExchangeFieldsFragment
  }
}

export default function indexingByAllExchanges({
  currencyExchanges,
  cryptoCurrencyExchanges,
}: {
  currencyExchanges?: CurrencyExchangeFieldsFragment[] | null
  cryptoCurrencyExchanges?: CurrencyExchangeFieldsFragment[] | null
}): IndexingByAllExchanges {
  // 명목화폐 환율
  const indexedCurrencyExchanges = (currencyExchanges || []).reduce(
    (acc: IndexingByAllExchanges, exchange) => {
      if (exchange.symbol && !acc.fiat[exchange.symbol] && !!Number(exchange.exchangeRate)) {
        acc.fiat[exchange.symbol] = exchange
      }
      return acc
    },
    { fiat: {}, crypto: {} },
  )

  // 코인 환율
  return (cryptoCurrencyExchanges || []).reduce((acc, exchange) => {
    if (exchange.symbol) {
      const [base, counter] = exchange.symbol.split('_') || []
      if (base && counter && !acc.crypto[`${base}${counter}`] && !!Number(exchange.exchangeRate)) {
        acc.crypto[`${base}${counter}`] = exchange
      }
    }
    return acc
  }, indexedCurrencyExchanges)
}
