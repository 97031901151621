import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'

import mergeWithPagination from '@/utils/apollo-client/type-policies/merge-with-pagination'
import readWithPagination from '@/utils/apollo-client/type-policies/read-with-pagination'

import isomorphicApolloClient, { CreateApolloClientProps } from './isomorphic-apollo-client'
import getLink from './http-link'

function createNewsServerApolloClient({
  isServer,
}: CreateApolloClientProps = {}): ApolloClient<NormalizedCacheObject> {
  const client = new ApolloClient({
    ssrMode: isServer,
    link: getLink({ uri: process.env.NEXT_PUBLIC_THE_RICH_NEWS_SERVER_URL as string }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            myCrawlingNewsKeywords: {
              merge: false,
            },
            businessNews: {
              keyArgs: ['country'],
              read: readWithPagination,
              merge: mergeWithPagination,
            },
            holdingAssetNews: {
              keyArgs: ['country'],
              read: readWithPagination,
              merge: mergeWithPagination,
            },
            sortedNews: {
              keyArgs: ['sortType', 'language', 'country'],
              read: readWithPagination,
              merge: mergeWithPagination,
            },
            searchNewsByKeyword: {
              keyArgs: ['keyword'],
              read: readWithPagination,
              merge: mergeWithPagination,
            },
          },
        },
      },
    }),
  })

  return client
}

const newsServerApolloClient = isomorphicApolloClient(createNewsServerApolloClient)

export default newsServerApolloClient
