import { apiServerApolloClient } from '@/apollo-client'
import sendErrorLog from '@/service/error-log'
import {
  CryptoCurrencyExchangesDocument,
  CryptoCurrencyExchangesQuery,
  CryptoCurrencyExchangesQueryVariables,
  CurrencyExchangesDocument,
  CurrencyExchangesQuery,
  CurrencyExchangesQueryVariables,
} from '@/gql/generated-api1'

import indexingByAllExchanges from '../exchange/indexing-by-all-exchanges'

import type { CurrencyExchangesProps } from './currency-exchanges-hook'
import { exchangesStore } from './store'

type Props = CurrencyExchangesProps

// 중복 네트워킹을 피하기 위한 캐싱 처리
const cache: { fiat: Record<string, true>; crypto: Record<string, true> } = {
  fiat: {},
  crypto: {},
}

// 주의 해야할점은 한 페이지내에서 여러번 호출하는 경우가 있을 때는 꼭 exchanges 의존해줘야함
// ex) 포폴 상세에서 포폴 자산 로직과 뉴스 로직과 같이 서로 다른 비즈니스에서 해당 함수를 2번 호출
export default async function fetchCurrencies({ fiatPairs = [], cryptoPairs = [] }: Props) {
  const exchanges = exchangesStore()

  if (fiatPairs.length + cryptoPairs.length === 0) return { exchanges }

  // 법정화폐 네트워킹(항상 신선한 데이터 필요시 filter 제거)
  const noDataFiatPairs = fiatPairs
    .filter(({ base, counter }) => {
      const pair = `${base}${counter}`
      return !exchanges.fiat[pair]?.exchangeRate && !cache.fiat[pair] // 캐싱도 제외
    })
    .map(({ base, counter }) => {
      const pair = `${base}${counter}`
      cache.fiat[pair] = true // 중복 네트워크 요청을 피하기 위한 캐싱
      return pair
    })

  // 코인 네트워킹
  const noDataCryptoPairs = cryptoPairs
    .filter(({ base, counter }) => {
      const pair = `${base}${counter}`
      return !exchanges.fiat[pair]?.exchangeRate && !cache.crypto[pair]
    })
    .map(({ base, counter }) => {
      cache.crypto[`${base}${counter}`] = true
      return `${base}_${counter}`
    })

  try {
    const fiatCurrenciesPromise = noDataFiatPairs.length
      ? apiServerApolloClient().query<CurrencyExchangesQuery, CurrencyExchangesQueryVariables>({
          query: CurrencyExchangesDocument,
          variables: {
            pairs: noDataFiatPairs,
          },
        })
      : null

    const cryptoCurrenciesPromise = noDataCryptoPairs.length
      ? apiServerApolloClient().query<
          CryptoCurrencyExchangesQuery,
          CryptoCurrencyExchangesQueryVariables
        >({
          query: CryptoCurrencyExchangesDocument,
          variables: {
            pairs: noDataCryptoPairs,
          },
        })
      : null

    // 주의: exchangesStore 변경시 리렌더 되므로 반복 호출되지 않도록 주의
    if (fiatCurrenciesPromise || cryptoCurrenciesPromise) {
      const [fiatCurrenciesResp, cryptoCurrenciesResp] = await Promise.all([
        fiatCurrenciesPromise,
        cryptoCurrenciesPromise,
      ])
      const { fiat, crypto } = indexingByAllExchanges({
        currencyExchanges: fiatCurrenciesResp?.data.currencyExchanges,
        cryptoCurrencyExchanges: cryptoCurrenciesResp?.data.cryptoCurrencyExchanges,
      })
      const exchanges = exchangesStore()
      return {
        exchanges: exchangesStore({
          fiat: { ...exchanges.fiat, ...fiat },
          crypto: { ...exchanges.crypto, ...crypto },
        }),
      }
    }

    return { exchanges }
  } catch (error) {
    const exchanges = exchangesStore()
    sendErrorLog(error as Error)
    return { exchanges }
  }
}

// 웹 로드 초기시 지원되는 법정화폐를 모두 store에 넣기 위한 함수
export async function initFiatCurrencies() {
  try {
    const { data } = await apiServerApolloClient().query<
      CurrencyExchangesQuery,
      CurrencyExchangesQueryVariables
    >({
      query: CurrencyExchangesDocument,
      variables: { pairs: [] },
    })
    const { fiat } = indexingByAllExchanges({ currencyExchanges: data.currencyExchanges })
    const exchanges = exchangesStore()
    exchangesStore({
      ...exchanges,
      fiat: { ...exchanges.fiat, ...fiat },
    })
  } catch (error) {
    sendErrorLog(error as Error)
  }
}

// 사용안할 예정...
export async function initCryptoCurrencies() {
  try {
    const { data } = await apiServerApolloClient().query<
      CryptoCurrencyExchangesQuery,
      CryptoCurrencyExchangesQueryVariables
    >({
      query: CryptoCurrencyExchangesDocument,
      variables: {
        pairs: [],
      },
    })
    const { crypto } = indexingByAllExchanges({
      cryptoCurrencyExchanges: data.cryptoCurrencyExchanges,
    })
    const exchanges = exchangesStore()
    exchangesStore({
      ...exchanges,
      crypto: { ...exchanges.crypto, ...crypto },
    })
  } catch (error) {
    sendErrorLog(error as Error)
  }
}
