type EventType = 'focus' | 'focusin' | 'pageshow' | 'blur' | 'focusout' | 'pagehide'

/**
 * 브라우저 탭이 focus, blur 된 경우 callback 실행
 * @param callback
 */
export default function pageVisibilityChangeHandler(
  callback: (isVisibility: boolean) => void,
): void {
  let hidden = 'hidden'

  // Standards:
  if (hidden in document) document.addEventListener('visibilitychange', onchange)
  else if ((hidden = 'mozHidden') in document)
    document.addEventListener('mozvisibilitychange', onchange)
  else if ((hidden = 'webkitHidden') in document)
    document.addEventListener('webkitvisibilitychange', onchange)
  else if ((hidden = 'msHidden') in document)
    document.addEventListener('msvisibilitychange', onchange)
  // All others:
  else window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange

  function onchange(evt: { type: string }) {
    const v = true,
      h = false,
      evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h,
      }

    evt = evt || window.event

    if (evt.type in evtMap) callback(evtMap[evt.type as EventType])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    else callback(!document[hidden])
  }
}
