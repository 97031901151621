import {ApolloLink} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { createClient } from 'graphql-ws';

import getLink from './http-link'
import {Consumer} from "@rails/actioncable";

// @ts-ignore
const hasSubscriptionOperation = ({query: {definitions}}) => {
    return definitions.some(
        // @ts-ignore
        ({kind, operation}) => kind === 'OperationDefinition' && operation === 'subscription'
    )
}

export type ApiServerLinkProps = {
    cable?: Consumer | null
}

export default function apiServerLink({cable} : ApiServerLinkProps): ApolloLink {
    if (typeof window !== 'undefined' && cable != null) {
        console.log('apiServerLink', { cable })
        return ApolloLink.split(
            hasSubscriptionOperation,
            new ActionCableLink({cable}),
            ApolloLink.split(
                (operation) => operation.getContext().clientName === 'API2',
                getLink({uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER2_URL as string}), // clientName === 'API2'
                getLink({uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER_URL as string}), // clientName !== 'API2'
            )
        )
    }
    return ApolloLink.split(
        (operation) => operation.getContext().clientName === 'API2',
        getLink({uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER2_URL as string}), // clientName === 'API2'
        getLink({uri: process.env.NEXT_PUBLIC_WEB_BACK_SERVER_URL as string}), // clientName !== 'API2'
    )
}
