import Big from 'big.js'

if (!Big.prototype._safeDiv) {
  Big.prototype._safeDiv = Big.prototype.div
}
Big.prototype.div = function (n: string | number) {
  try {
    return +n === 0 ? Big(0) : this._safeDiv(n)
  } catch (e) {
    return Big(0)
  }
}
