import { from, ApolloLink } from '@apollo/client'
import { getI18n } from 'react-i18next'
import { createUploadLink } from 'apollo-upload-client'

import { getAuthToken } from '@/service/auth'

import getErrorHandlerLink from './error-handler-link'

const middleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const language = getI18n()?.language
    return {
      headers: {
        ...headers,
        authorization: `bearer ${getAuthToken()}`,
        ...(language ? { 'accept-language': language } : {}), // 주의: next.js서버에서 요청할 때는 앱 레벨에서 설정된 로케일을 읽지 못함.
      },
    }
  })

  return forward(operation)
})

function getHttpLink(uri: string): ApolloLink {
  return createUploadLink({ uri }) as unknown as ApolloLink
}

export default function getLink({ uri }: { uri: string }) {
  return from([middleware, getErrorHandlerLink(), getHttpLink(uri)])
}
