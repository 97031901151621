export type GetLocalStorageProps<FallbackValueType = string> = {
  key: string
  type?: 'string' | 'array' | 'number' | 'object' | 'boolean'
  fallbackValue?: FallbackValueType
}

// 로컬스토리지 json parse로 가져올때 에러나는 경우 대응할 수 있는 안전한 함수
export const getLocalStorage = <FallbackValueType, ReturnType = FallbackValueType>({
  key,
  type = 'string',
  fallbackValue,
}: GetLocalStorageProps<FallbackValueType>): FallbackValueType | ReturnType | string => {
  if (!global?.localStorage) return fallbackValue || ''

  // 로컬스토리지는 저장 반환 only string 타입
  const dataStr = localStorage.getItem(key)

  try {
    if (dataStr) {
      if (type === 'string' && typeof dataStr === 'string') {
        return /".*"/.test(dataStr) ? `${JSON.parse(dataStr)}` : dataStr
      }

      const data = JSON.parse(dataStr)

      if (
        (type === 'number' && typeof data === 'number') ||
        (type === 'boolean' && typeof data === 'boolean') ||
        (type === 'array' && Array.isArray(data)) ||
        (type === 'object' && typeof data === 'object')
      ) {
        return data
      } else if (fallbackValue) {
        return fallbackValue
      }

      return data
    }
  } catch (error) {
    return fallbackValue || ''
  }

  return fallbackValue || ''
}

export const setLocalStorage = <Value>({ key, value }: { key: string; value: Value }): void => {
  if (!global?.localStorage) return

  localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorage = ({ key }: { key: string }): void => {
  if (!global?.localStorage) return

  localStorage.removeItem(key)
}
