import Appsignal from '@appsignal/javascript'
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console'
// import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network'
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator'

import { plugin as breadcrumbsFetchPlugin } from './appsignal-fetch-plugin'

export const appsignal = new Appsignal({
  key: process.env.NEXT_PUBLIC_APPSIGNAL,
  revision: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  ignoreErrors: [
    /Can't find variable: IntersectionObserver/, // safari에서 나오는 이슈로 서비스 사용에는 문제없어서 무시
    /Object\.fromEntries is not a function/, // 브라우저 낮은 버전에서 발생하는 이슈로 별도 대응하지 않음(Object.fromEntries는 tiptap에서 사용)
    /Response not successful: Received status code 403/, // 403 API 서버 인증 에러
  ],
})

if (typeof global.window !== 'undefined') {
  appsignal.use(breadcrumbsConsolePlugin())
  appsignal.use(breadcrumbsFetchPlugin())
  appsignal.use(pathDecoratorPlugin())
}
