import mergeForPagination from './merge-for-pagination'

export default function mergeWithPagination<Item>(
  existing: Item[],
  incoming: Item[],
  { args }: { args: Record<string, number> | null },
) {
  const page = args?.page ?? 0
  const limit = args?.limit ?? existing?.length ?? 0
  return mergeForPagination({ existing, incoming, page, limit })
}
